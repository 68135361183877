export const getAuth = () => {
    return JSON.parse(localStorage.getItem('AUTH_TOKEN'))
}

export const getNum = () => {
    return JSON.parse(localStorage.getItem('NUM'))
}

export const getCode = () => {
    return JSON.parse(localStorage.getItem('CODE'))
}

export const getSwitchUser = () => {
    return JSON.parse(localStorage.getItem('SWITCH_USER'))
}

export const logout = () => {
    window.location.pathname = '/'
    localStorage.removeItem('SWITCH_USER')
    localStorage.removeItem('AUTHENTIFIER')
    localStorage.removeItem('LAST_URL')
    localStorage.removeItem('NUM')
}